import React from 'react';
import Buttons from './Buttons';
const background = require('/Users/oskarwroz/Documents/WebDev-Projects/gleneagle/gleneagle/src/assets/jumpsuit_promo_compressed.jpg');

export default function MobileMain() {
  return (
    <div
      className='mobile-main'
      style={{
        backgroundImage: `url(${background})`
      }}
    >
      <h1>GLENEAGLE</h1>

      <img className='image' src={background} />
      <br></br>
      <div>PRE-SAVE SOMEDAY WE'LL GO WALKING IN THE SUN</div>
      <Buttons />
    </div>
  );
}
