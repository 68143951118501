import { useState, useCallback } from 'react';
import Buttons from './Buttons';
import { ReactComponent as YourSvg } from '/Users/oskarwroz/Documents/WebDev-Projects/gleneagle/gleneagle/src/assets/someday.svg';

const background = require('/Users/oskarwroz/Documents/WebDev-Projects/gleneagle/gleneagle/src/assets/jumpsuit_promo_compressed.jpg');

export default function Main() {
  const [hover, setHover] = useState();

  const handleHover = useCallback(() => {
    setHover(true);
  }, []);

  const handleMouseOut = useCallback(() => {
    setHover(false);
  }, []);

  return (
    <>
      <div
        className='main'
        style={{
          backgroundImage: `url(${background})`
        }}
      >
        <div>
          <h1>GLENEAGLE</h1>

          <div className='pre-save-wrapper'>
            <h2>NEW SINGLE OUT NOW</h2>
            <div className='svg-wrapper'>
              <a
                href='https://distrokid.com/hyperfollow/gleneagle/some-well-go-walking-in-the-sun'
                target='_blank'
                rel='noreferrer'
              >
                <YourSvg
                  fill={hover ? '#ffee00' : '#fff'}
                  onMouseOver={handleHover}
                  onMouseLeave={handleMouseOut}
                />
              </a>
            </div>
          </div>
          <iframe
            className='spotify-iframe'
            src='https://open.spotify.com/embed/track/6thKmb0b9ZeNCD9vw2FZmb'
            width='300'
            height='80'
            frameborder='0'
            allowtransparency='true'
            allow='encrypted-media'
            title='spotify-player'
          />
          {/* <Buttons /> */}
        </div>
      </div>
    </>
  );
}
