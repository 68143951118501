import React from 'react';

export default function Buttons() {
  return (
    <div>
      <iframe
        title='spotify-player'
        src='https://open.spotify.com/embed/album/0xAM0r1i0kxa0gkw0jZKCa'
        width='300'
        height='80'
        frameborder='0'
        allowtransparency='true'
        allow='encrypted-media'
      ></iframe>
    </div>
  );
}
