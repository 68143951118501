import Main from './components/Main';
import isMobile from 'ismobilejs';
import MobileMain from './components/MobileMain';

function App() {
  const mobileCheck = isMobile(window.navigator);

  const mobile = mobileCheck.apple.device || mobileCheck.android.device;

  if (mobile) {
    return <MobileMain />;
  }
  return (
    <div className='App'>
      <Main />
    </div>
  );
}

export default App;
